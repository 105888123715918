.compliance-calender-table-container {
  margin-top: 10px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.compliance-calender-select-month-container-header {
  display: flex;
  justify-content:space-between;
}

.compliance-calender-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compliance-calender-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.compliance-calender-table,
.compliance-calender-table-thead {
  padding: 8px;
  text-align: left;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.compliance-calender-table-body tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.compliance-calender-table-body tr:nth-child(even) {
  background-color: #fff;
}

.compliance-calender-table-body tr:hover {
  background-color: #ffe6e6; 
}

.compliance-calender-table-thead {
  position: sticky;
  top: 0;
  background: #B22222; 
  color: white;
  z-index: 100;
}

.compliance-calender-table-td {
  width: 100px;
  border: 0 !important;
  text-align: center;
}

.compliance-calender-table-th {
  border: 0 !important;
  text-align: center;
}

.compliance-calender-month-container-header-select-container {
  display: flex;
  gap: 10px;
  border: 0 !important;
}

.compliance-calender-dialog-header-title {
  color: #B22222;
  border: 0 !important;

}

.compliance-calender-no-data-found {
  padding: 16px;
}