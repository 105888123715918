
  table {
    font-family: arial, sans-serif;

    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid rgb(138, 137, 137);
    text-align: left;
    
    padding: 10px;
    font-size: 16px;
  }
  .title td{
text-align: center;
font-size: 16px;
font-weight: bold;
position: sticky;
  }
  .accepted{
    background:#90EE90;
  }
 .rejected{
  background-color:#FAA0A0;
 }
 .pending{
  background-color:#F5F5F5;
 }
textarea{
  border:1px solid #b6b4b4;
}
 






